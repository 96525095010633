import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import './layout.css'
import { ThemeProvider } from '@material-ui/styles'
import { theme } from '../theme'

const Main = styled.main`
  margin: 0 auto;
  max-width: 960px;
  padding: 0 1.075rem 2rem;

  @media (max-width: 500px) {
    width: 100%;
    padding: 0;
  }
`

const Footer = styled.footer`
  font-size: 0.75em;
  text-align: center;
  margin: 0 auto;
  max-width: 960px;
  padding: 0px 1.0875rem;
`

const Layout = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <ThemeProvider theme={theme}>
          <Main>{children}</Main>
          <Footer>© {new Date().getFullYear()} Christopher Clemons</Footer>
        </ThemeProvider>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
